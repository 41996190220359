import * as React from 'react';
import { observer } from 'mobx-react';
import { appState } from '../stores/appstate';
import NoteListItem from './NoteListItem';

const notesFilter = note => {
  if (appState.tagFilter === null) {
    return true;
  }

  if (!note.tags) {
    return false;
  }

  return note.tags.some(tag => tag.tagId === appState.tagFilter);
};

const NoteList = () => {
  return (
    <div className="note-list">
      {appState.notes.filter(notesFilter).map(note => (
        <NoteListItem note={note} key={note.noteId} />
      ))}
    </div>
  );
};

export default observer(NoteList);
