import * as React from 'react';
import LoginLogoutButton from './LoginLogout';
import { addBlankNote } from '../api/notes';
import TagList from './TagList';
import { appState } from '../stores/appstate';

export default () => {
  return (
    <div className="app-sidebar">
      <h1>Jecko</h1>
      <LoginLogoutButton />
      <button className="note-input-create-button" onClick={addBlankNote}>
        + Create Note
      </button>
      <TagList />
    </div>
  );
};
