import { checkAuth } from './auth';
import { refreshNotes, refreshTags } from './notes';

export const initApp = async () => {
  const isAuthed = await checkAuth();
  if (!isAuthed) {
    return;
  }
  await refreshNotes();
  await refreshTags();
};
