import * as React from 'react';
import { observer } from 'mobx-react';
import { appState } from '../stores/appstate';

const LOGIN_URL = `https://api.jecko.app/account/login?redirect=${
  window.location
}`;
const LOGOUT_URL = `https://api.jecko.app/account/logout?redirect=${
  window.location
}`;

const LoginLogoutButton = () => {
  const data = appState.isLoggedIn
    ? {
        text: 'Logout',
        url: LOGOUT_URL,
      }
    : {
        text: 'Login',
        url: LOGIN_URL,
      };

  return (
    <button
      className="login-button"
      onClick={() => (window.location = data.url)}
    >
      {data.text}
    </button>
  );
};

export default observer(LoginLogoutButton);
