import * as React from 'react';
import { observer } from 'mobx-react';
import { appState } from '../stores/appstate';
import { saveCurrentNote } from '../api/notes';
import { set } from 'mobx';
import SimpleMDE from 'react-simplemde-editor';
import 'simplemde/dist/simplemde.min.css';

class NoteInputForm extends React.Component {
  timerValue = null;

  markDirty() {
    appState.currentNoteDirty = true;

    if (this.timerValue != null) {
      clearTimeout(this.timerValue);
    }

    this.timerValue = setTimeout(() => {
      saveCurrentNote();
    }, 3000);
  }

  onInputChange = evt => {
    const currentNote = appState.currentNoteObject;

    if (currentNote) {
      if (evt.target.name === 'tags') {
        set(appState, {
          currentNoteTags: evt.target.value,
        });
      } else {
        set(currentNote, {
          [evt.target.name]: evt.target.value,
        });
      }

      this.markDirty();
    }
  };

  handleEditorChange = value => {
    const currentNote = appState.currentNoteObject;
    if (!currentNote) {
      return;
    }

    set(currentNote, {
      body: value,
    });

    this.markDirty();
  };

  submitForm = evt => {
    evt.preventDefault();
    saveCurrentNote();
  };

  render() {
    const currentNote = appState.currentNoteObject;
    if (!currentNote) {
      return null;
    }

    return (
      <form className="note-input-form" onSubmit={this.submitForm}>
        <div className="note-input-form-topbar">
          <input
            name="title"
            className="note-input-form-title"
            placeholder="Title"
            onChange={this.onInputChange}
            value={currentNote.title}
          />
          <button
            className={`note-input-form-save-button ${
              appState.currentNoteDirty ? 'dirty' : ''
            }`}
            type="submit"
            disabled={!appState.currentNoteDirty}
          >
            {appState.currentNoteDirty ? 'Save' : 'Saved'}
          </button>
        </div>
        <div className="note-input-form-tagbar">
          <input
            name="tags"
            className="note-input-form-tagbar-tags"
            placeholder="Tags"
            onChange={this.onInputChange}
            value={appState.currentNoteTags}
          />
        </div>
        <SimpleMDE
          className="editor"
          onChange={this.handleEditorChange}
          value={currentNote.body}
        />
      </form>
    );
  }
}

export default observer(NoteInputForm);
