import { observable, computed } from 'mobx';

class AppState {
  @observable isLoggedIn = false;
  @observable currentNote = null;
  @observable notes = [];
  @observable tags = [];
  @observable currentNoteDirty = false;
  @observable currentNoteTags = '';
  @observable tagFilter = null;

  @computed get currentNoteObject() {
    return this.notes.find(note => note.noteId === this.currentNote);
  }

  @computed get sortedTags() {
    return this.tags.slice().sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  @computed get tagTree() {
    const allTags = this.sortedTags;
    const subTags = allTags.filter(t => t.name.includes('\\'));
    const mainTags = allTags.filter(t => !t.name.includes('\\'));

    let fakeTagId = -1;

    const insertTag = (tag, parts, addTo = null) => {
      if (addTo == null) {
        addTo = mainTags.find(t => t.name === parts[0]);
        if (!addTo) {
          return false;
        }
      }

      addTo.children = addTo.children || [];
      if (parts.length === 2) {
        addTo.children.push({
          ...tag,
          name: parts[1],
        });
        return true;
      }

      let candidate = addTo.children.find(t => t.name === parts[1]);
      if (!candidate) {
        candidate = observable.object({
          tagId: fakeTagId--,
          name: parts[1],
        });
        addTo.children.push(candidate);
      }

      addTo = candidate;

      return insertTag(tag, parts.slice(1), addTo);
    };

    for (const tag of subTags) {
      const parts = tag.name.split('\\');
      if (!insertTag(tag, parts)) {
        mainTags.push(tag);
      }
    }

    return mainTags;
  }
}

export const appState = new AppState();
