import * as React from 'react';
import * as ReactDOM from 'react-dom';
import NoteList from './components/NoteList';
import { initApp } from './api/init';
import NoteInputForm from './components/NoteInputForm';
import AppSidebar from './components/AppSidebar';

class App extends React.Component {
  componentDidMount() {
    initApp();
  }

  render() {
    return (
      <div className="app">
        <AppSidebar />
        <NoteList />
        <NoteInputForm />
      </div>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('app'));
