import * as React from 'react';
import { observer } from 'mobx-react';
import { appState } from '../stores/appstate';

class RecurseTags extends React.Component {
  state = {
    collapsed: false,
  };

  collpaseHandler = id => {
    this.setState({
      [id]: !this.isCollapsed(id),
    });
  };

  isCollapsed = id => {
    return this.state[id] === undefined ? true : this.state[id];
  };

  render() {
    const { tags } = this.props;
    if (!tags) {
      return null;
    }

    const tagSelectHandler = id => {
      appState.tagFilter = id;
    };

    const collpaseButton = tag => {
      if (!tag.children) {
        return null;
      }

      const expansionButton = this.isCollapsed(tag.tagId) ? '+' : '-';
      return (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => this.collpaseHandler(tag.tagId)}
        >
          {expansionButton}{' '}
        </span>
      );
    };

    return (
      <ul>
        {tags.map(tag => (
          <React.Fragment>
            <li
              style={{
                listStyle: 'none',
              }}
            >
              {collpaseButton(tag)}
              <a
                style={{
                  fontWeight: appState.tagFilter === tag.tagId ? 900 : 400,
                  cursor: 'pointer',
                }}
                onClick={() => tagSelectHandler(tag.tagId)}
              >
                {tag.name}
              </a>
            </li>
            {this.isCollapsed(tag.tagId) ? null : (
              <RecurseTags tags={tag.children} />
            )}
          </React.Fragment>
        ))}
      </ul>
    );
  }
}

class TagList extends React.Component {
  render() {
    return (
      <div className="tag-list">
        <h2>Tags</h2>
        {appState.tagFilter && (
          <button onClick={() => (appState.tagFilter = null)}>All Notes</button>
        )}
        <RecurseTags tags={appState.tagTree} />
      </div>
    );
  }
}

export default observer(TagList);
