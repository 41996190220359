import { AuthedClient } from './AuthedClient';
import { appState } from '../stores/appstate';
import { set } from 'mobx';

export const checkAuth = async () => {
  try {
    await AuthedClient.get('/account/token');
    set(appState, {
      isLoggedIn: true,
    });
    return true;
  } catch (error) {
    set(appState, {
      isLoggedIn: false,
    });
  }
  return false;
};
