import * as React from 'react';
import { observer } from 'mobx-react';
import { appState } from '../stores/appstate';
import { set } from 'mobx';
import ContextMenu from './ContextMenu';
import { deleteNote } from '../api/notes';

class NoteListItem extends React.Component {
  state = {
    menuOpen: false,
    menuX: 0,
    menuY: 0,
  };

  onClicked = evt => {
    set(appState, {
      currentNote: this.props.note.noteId,
      currentNoteTags: this.props.note.tags.map(t => t.name).join(' '),
    });
  };

  contextMenuHandler = evt => {
    evt.preventDefault();
    this.setState({ menuOpen: true, menuX: evt.clientX, menuY: evt.clientY });
  };

  deleteItemHandler = evt => {
    evt.preventDefault();
    deleteNote(this.props.note.noteId);
  };

  render() {
    const current = appState.currentNote;
    const { title, body, noteId } = this.props.note;
    const { menuOpen, menuX, menuY } = this.state;
    const otherClasses = noteId === current ? 'selected' : '';
    return (
      <div
        className={`note-list-item ${otherClasses}`}
        onClick={this.onClicked}
        onContextMenu={this.contextMenuHandler}
      >
        <div className="note-list-item-title">{title}</div>
        <div className="note-list-item-summary">{body.substr(0, 20)}</div>
        {menuOpen ? (
          <ContextMenu
            x={menuX}
            y={menuY}
            lostFocus={() => this.setState({ menuOpen: false })}
          >
            <ul>
              <a onClick={this.deleteItemHandler}>Delete</a>
            </ul>
          </ContextMenu>
        ) : null}
      </div>
    );
  }
}

export default observer(NoteListItem);
