import { AuthedClient } from './AuthedClient';
import { appState } from '../stores/appstate';
import { set } from 'mobx';

export const refreshNotes = async () => {
  const resp = await AuthedClient.get('/api/notes');
  appState.notes.replace(resp.data);
};

export const refreshTags = async () => {
  const resp = await AuthedClient.get('/api/tags');
  appState.tags.replace(resp.data);
};

export const addNote = async note => {
  const resp = await AuthedClient.post('/api/notes', note);
  const newNote = {
    ...note,
    noteId: resp.data.id,
  };
  appState.notes.push(newNote);
  return resp.data.id;
};

export const addBlankNote = async () => {
  const id = await addNote({
    title: 'New Note',
    body: '',
  });

  appState.currentNote = id;
  appState.currentNoteTags = '';
};

export const saveCurrentNote = async () => {
  const currentNote = appState.currentNoteObject;
  const newNote = {
    ...currentNote,
    tags: appState.currentNoteTags.split(' '),
  };
  await AuthedClient.put(`/api/notes/${currentNote.noteId}`, newNote);
  appState.currentNoteDirty = false;
  await refreshTags();
};

export const deleteNote = async id => {
  await AuthedClient.delete(`api/notes/${id}`);
  appState.notes = appState.notes.filter(note => note.noteId !== id);
  appState.currentNote = null;
  await refreshTags();
};
