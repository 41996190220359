import * as React from 'react';

export default class ContextMenu extends React.Component {
  focusLossHandler = evt => {
    if (event.target.closest('.context-menu')) {
      return;
    }

    this.props.lostFocus();
  };

  componentWillMount() {
    document.addEventListener('click', this.focusLossHandler);
    document.addEventListener('contextmenu', this.focusLossHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.focusLossHandler);
    document.removeEventListener('contextmenu', this.focusLossHandler);
  }

  render() {
    return (
      <div
        className="context-menu"
        style={{
          position: 'fixed',
          left: `${this.props.x}px`,
          top: `${this.props.y}px`,
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
